import { HttpClientModule } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { MessageService } from 'primeng/api'; // Use the correct import for MessageService
import { HttpClientService } from '../../services/http.client.service';

@Component({
  selector: 'app-add-update-product',
  templateUrl: './add-update-product.component.html',
  styleUrls: ['./add-update-product.component.css'],
  providers: [MessageService],
})
export class AddUpdateProductComponent implements OnInit {
  @ViewChild('ConfirmModal', { static: false }) ConfirmModal: ModalDirective;
  isFetched: boolean = false;
  list1: any[] = [];
  list2: any[] = [];
  showErrorMessageDiv: boolean = false; // Initialize this property
  tosterMessage: string = '';
  toast: boolean = false;
  errorMessages = { message: '', details: '' };
  skuValue: any = '';
  constructor(private httpservice: HttpClientService, private messageService: MessageService) { }

  ngOnInit() {
    this.validateCAServiceList();
    setTimeout(() => {
      this.openConfirmationModel();
    }, 1000);
  }

  openConfirmationModel() {
    this.ConfirmModal.show();
    this.validateCAServiceList();
  }

  closeConfirmModal() {
    this.isFetched = false;
    this.ConfirmModal.hide();
  }

  fetch(data: string) {
    debugger;
    this.isFetched = true;
    if ([null, undefined, ''].includes(data)) {
      this.messageService.add({ severity: 'error', summary: 'Please Enter Sku.', detail: '' });
    } else {
      this.httpservice.get(`api/Product/ValidateProductCA_ServicebyPassingSKU/` + data).subscribe(
        (res: any) => {
          console.log(JSON.stringify(res) + 'fdd');
          //this.showTosterMessage(res.message || 'Product created and updated successfully!', '', 'success');
        //  this.messageService.add({ severity: 'success', summary: res.message, detail: '' });
          if (res.status === 200) {
            this.messageService.add({ severity: 'success', summary: res.message, detail: '' });
            this.skuValue = '';
            this.isFetched = false;
          } else if (res.status === 404) {
            //console.log("No products or product variants found to validate.");
            this.messageService.add({ severity: 'error', summary: res.message, detail: '' });
          }
          else if (res.status === 400) {
            //console.log("No products or product variants found to validate.");
            this.messageService.add({ severity: 'error', summary: res.message, detail: '' });
          }
        },
        (error) => {
          //this.showTosterMessage(error.message || 'An error occurred!', '', 'error');
          this.messageService.add({ severity: 'error', summary: error.message, detail: '' });
        }
      );
    }
  }

  validateCAServiceList() {
    this.httpservice.get(`api/Product/validateCAServiceList`).subscribe(
      (response: any) => {
        if (response && response.products && Array.isArray(response.products) && response.productVariants && Array.isArray(response.productVariants)) {
          this.list1 = response.products;
          this.list2 = response.productVariants;
        } else {
          console.warn("Products or Product Variants are not Updated in last 24 hours");
          this.list1 = [];
          this.list2 = [];
        }
      },
      (error) => {
        console.error("Error fetching data", error);
        this.list1 = [];
        this.list2 = [];
      }
    );
  }

  showTosterMessage(message: string, detail: string = '', severity: string = 'error') {
    this.messageService.add({ severity, summary: message, detail });
    setTimeout(() => {
      this.messageService.clear(); 
    }, 5000);
  }
}
