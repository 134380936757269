import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'afterCommaSpace'
})
export class AfterCommaSpacePipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return value; // Handle null or undefined
    return value.replace(/,/g, ', '); // Add space after every comma
  }

}
